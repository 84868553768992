import * as AuthTypes from './profile.types';
import * as AuthLoginTypes from '../auth/login/authLogin.types';
import { RequestStatus } from '../models/request';

const INITIAL_STATE = {
  data: {
    name: null,
    lastName: null,
    picture: null,
    language: 'pt-BR',
    currency: 'BRL',
    contact: null
  },
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case AuthTypes.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: RequestStatus.success
      };
    }
    case AuthTypes.GET_USER_PROFILE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case AuthLoginTypes.LOGOUT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default reducer;
