import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// redux
// import { useAuthLoginStatus } from '../store/auth/login/authLogin.selectors';
// import { getUserProfile } from '../store/profile/profile.actions';
// import { useProfileStatus } from '../store/profile/profile.selectors';
// import { getPurchases } from '../store/order/purchases/orderPurchases.actions';
// utils
// import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

// import { useAuthLoginData, useAuthLoginStatus } from '../store/auth/login/authLogin.selectors';
// import { login as authLogin } from '../store/auth/login/authLogin.actions';
import { registerTeacher } from '../store/auth/register/teacher/account/authRegisterTeacherAccount.actions';
import { initialize } from '../store/auth/initialize/authInitialize.actions';
// import * as authActions from '../store/auth/initialize/authInitialize.actions';

// const initialState = {
//   isAuthenticated: false,
//   isInitialized: false,
//   user: null
// };

// const handlers = {
//   INITIALIZE: (state, action) => {
//     const { isAuthenticated, user } = action.payload;
//     return {
//       ...state,
//       isAuthenticated,
//       isInitialized: true,
//       user
//     };
//   },
//   LOGIN: (state, action) => {
//     const { user } = action.payload;

//     return {
//       ...state,
//       isAuthenticated: true,
//       user
//     };
//   },
//   LOGOUT: (state) => ({
//     ...state,
//     isAuthenticated: false,
//     user: null
//   }),
//   REGISTER: (state, action) => {
//     const { user } = action.payload;

//     return {
//       ...state,
//       isAuthenticated: true,
//       user
//     };
//   }
// };

// const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  // const { isSuccess: isSuccessLogin } = useAuthLoginStatus();
  // const { isSuccess: isSuccessProfile } = useProfileStatus();
  // const [state, dispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  // const loginData = useAuthLoginData();

  useEffect(() => {
    console.log('dispatch(initialize())');
    dispatch(initialize());
  }, [dispatch]);

  // useEffect(() => {
  //   // if (isSuccessLogin) {
  //   //   dispatch(getUserProfile());
  //   // }
  //   // if (isSuccessProfile) {
  //   //   dispatch(getPurchases());
  //   // }
  // }, [isSuccessProfile, isSuccessLogin]);

  // useEffect(() => {
  //   console.log('initialize()');

  //   const initialize = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem('accessToken');

  //       if (accessToken && isValidToken(accessToken)) {
  //         setSession(accessToken);

  //         const response = await dispatch(authActions.getUser(accessToken));
  //         console.log('myAccount', response);

  //         // const user = {
  //         //   accessToken,
  //         //   user: {
  //         //     id: '8864c717-587d-472a-929a-8e5f298024da-0',
  //         //     displayName: 'Jaydon Frankie',
  //         //     email: response?.data?.contact?.email,
  //         //     password: '',
  //         //     photoURL: response?.data?.picture,
  //         //     phoneNumber: '+40 777666555',
  //         //     country: 'United States',
  //         //     address: '90210 Broadway Blvd',
  //         //     state: 'California',
  //         //     city: 'San Francisco',
  //         //     zipCode: '94116',
  //         //     about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
  //         //     role: 'admin',
  //         //     isPublic: true,
  //         //     ...response.data
  //         //   }
  //         // };

  //         dispatch(authActions.initialize({ isAuthenticated: true, isInitialized: true }));

  //         console.log({ loginData });

  //         // const response = await axios.get('/api/account/my-account');
  //         // const { user } = response.data;
  //         // console.log(user);

  //         // dispatch({
  //         //   type: 'INITIALIZE',
  //         //   payload: {
  //         //     isAuthenticated: true,
  //         //     user
  //         //   }
  //         // });
  //       } else {
  //         dispatch(authActions.initialize({ isAuthenticated: false, isInitialized: true, user: null }));
  //         // dispatch({
  //         //   type: 'INITIALIZE',
  //         //   payload: {
  //         //     isAuthenticated: false,
  //         //     user: null
  //         //   }
  //         // });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch(authActions.initialize({ isAuthenticated: false, isInitialized: true, user: null }));
  //       // dispatch({
  //       //   type: 'INITIALIZE',
  //       //   payload: {
  //       //     isAuthenticated: false,
  //       //     user: null
  //       //   }
  //       // });
  //     }
  //   };

  //   initialize();
  // }, []);

  // useEffect(() => {
  //   dispatch(authActions.initialize());
  // }, []);

  // const login = async (payload) => {
  //   console.log('CONTEXT GET LOGIN');
  //   const response = await dispatch(authLogin(payload));
  //   const { data } = response;
  //   // const { token } = data;

  //   console.log({ response, payload, data });

  //   // const responseGetUser = await dispatch(authActions.getUser(data?.token));
  //   // console.log('myAccount', responseGetUser);

  //   // const user = {
  //   //   accessToken: data?.token,
  //   //   user: {
  //   //     id: '8864c717-587d-472a-929a-8e5f298024da-0',
  //   //     displayName: 'Jaydon Frankie',
  //   //     email: responseGetUser?.data?.contact?.email,
  //   //     password: '',
  //   //     photoURL: responseGetUser?.data?.picture,
  //   //     phoneNumber: '+40 777666555',
  //   //     country: 'United States',
  //   //     address: '90210 Broadway Blvd',
  //   //     state: 'California',
  //   //     city: 'San Francisco',
  //   //     zipCode: '94116',
  //   //     about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
  //   //     role: 'admin',
  //   //     isPublic: true,
  //   //     ...responseGetUser.data
  //   //   }
  //   // };

  //   // dispatch(authActions.initialize({ isAuthenticated: true, isInitialized: true, user }));

  //   // const response = await axios.post('/api/account/login', {
  //   //   email,
  //   //   password
  //   // });

  //   // const { accessToken, user } = response.data;
  //   // const { token } = data;

  //   setSession(data?.token);
  //   // dispatch({
  //   //   type: 'LOGIN',
  //   //   payload: {
  //   //     user
  //   //   }
  //   // });

  //   return response;
  // };

  const register = async (email, password, firstName, lastName) => {
    const payload = {
      name: firstName,
      lastName,
      picture: '',
      email,
      password,
      confirmPassword: password,
      language: 'pt-BR',
      currency: 'BRL'
    };

    // const response = await axios.post('/api/account/register', {
    //   email,
    //   password,
    //   firstName,
    //   lastName
    // });
    // const { accessToken, user } = response.data;

    const response = await dispatch(registerTeacher(payload));
    // const { data } = response;

    // console.log('DATTTTAAAA', data);

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user
    //   }
    // });
    return response;
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        // ...loginData,
        method: 'jwt',
        // login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
