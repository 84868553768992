// components
import { getLessonPlanOptionsFileFormatType as getLessonPlanOptionsFileFormatTypeService } from './lessonPlanOptionsFileFormatType.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsFileFormatType.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsFileFormatTypeRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_FORMAT_TYPE_REQUEST
});

export const getLessonPlanOptionsFileFormatTypeSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_FORMAT_TYPE_SUCCESS,
  payload
});

export const getLessonPlanOptionsFileFormatTypeFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_FORMAT_TYPE_FAILURE,
  payload: error
});

export const getLessonPlanOptionsFileFormatType = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsFileFormatTypeRequest());

    const response = await getLessonPlanOptionsFileFormatTypeService();
    dispatch(getLessonPlanOptionsFileFormatTypeSuccess([...response?.data.data]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsFileFormatTypeFailure({
        message: 'Occured error in action getLessonPlanOptionsFileFormatType',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsFileFormatType', ...error?.response?.data };
  }
};
