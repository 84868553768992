// components
import { getUserProfile as getUserProfileService } from './profile.services';
// variables
import * as AuthTypes from './profile.types';

// -----------------------------------------------------------------------------------
// GET PROFILE
export const getUserProfileRequest = () => ({
  type: AuthTypes.GET_USER_PROFILE_REQUEST
});

export const getUserProfileSuccess = (payload) => ({
  type: AuthTypes.GET_USER_PROFILE_SUCCESS,
  payload
});

export const getUserProfileFailure = (error) => ({
  type: AuthTypes.GET_USER_PROFILE_FAILURE,
  payload: error
});

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(getUserProfileRequest());
    const { data } = await getUserProfileService();
    dispatch(getUserProfileSuccess(data.data));

    return data.data;
  } catch (error) {
    dispatch(
      getUserProfileFailure({
        message: 'Occured error in action get profile',
        errors: error.response.data.errors
      })
    );
    return { message: 'Occured error in action get profile', ...error.response.data };
  }
};
