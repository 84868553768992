import * as LessonPlanFilterProductTypes from './lessonPlanFilterProduct.types';
import { RequestStatus } from '../../models/request';

const INITIAL_STATE = {
  data: {
    language: 'pt-BR',
    currency: 'BRL',
    pageIndex: 1,
    textToSearch: '',
    name: '',
    description: '',
    tag: '',
    productLessonType: null,
    level: [],
    duration: [],
    skill: [],
    media: [],
    englishType: [],
    methodology: [],
    phoneme: [],
    activity: [],
    profession: [],
    category: [],
    subject: [],
    vocabulary: [],
    word: [],
    pronunciation: [],
    grammar: [],
    functional: [],
    priceFrom: null,
    priceTo: null
  },
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_CATEGORY:
      return {
        ...state,
        data: {
          ...state.data,
          category: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_DURATION:
      return {
        ...state,
        data: {
          ...state.data,
          duration: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_LEVEL:
      return {
        ...state,
        data: {
          ...state.data,
          level: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_SKILL:
      return {
        ...state,
        data: {
          ...state.data,
          skill: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_MULTIMIDIA:
      return {
        ...state,
        data: {
          ...state.data,
          media: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_TEXT:
      return {
        ...state,
        data: {
          ...state.data,
          textToSearch: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_PRODUCT_VOCABULARY:
      return {
        ...state,
        data: {
          ...state.data,
          vocabulary: action.payload
        }
      };
    case LessonPlanFilterProductTypes.LESSEON_PLAN_FILTER_RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
