// components
import { getLessonPlanOptionsSkill as getLessonPlanOptionsSkillService } from './lessonPlanOptionsSkill.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsSkill.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsSkillRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SKILL_REQUEST
});

export const getLessonPlanOptionsSkillSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SKILL_SUCCESS,
  payload
});

export const getLessonPlanOptionsSkillFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SKILL_FAILURE,
  payload: error
});

export const getLessonPlanOptionsSkill = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsSkillRequest());

    const response = await getLessonPlanOptionsSkillService();
    dispatch(getLessonPlanOptionsSkillSuccess([...response?.data.data]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsSkillFailure({
        message: 'Occured error in action getLessonPlanOptionsSkill',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsSkill', ...error?.response?.data };
  }
};
