import * as EcommerceTypes from './ecommerceCheckout.types';
import { RequestStatus } from '../../../../models/request';

const INITIAL_STATE = {
  data: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    payment: 'paypal',
    billing: {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      receiver: 'Download online',
      fullAddress: '',
      phone: 'Não é necessário cartão de crédito.',
      addressType: 'Digital',
      isDefault: true
    }
  },
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EcommerceTypes.GET_SHOPPING_CART_SUCCESS: {
      const subtotal = action.payload.reduce((previousValue, currentValue) => previousValue + currentValue.subtotal, 0);

      return {
        ...state,
        data: {
          ...state.data,
          cart: action.payload,
          subtotal,
          total: subtotal
        },
        error: null,
        status: RequestStatus.success
      };
    }
    case EcommerceTypes.GET_SHOPPING_CART_REQUEST: {
      return {
        ...state,
        error: null,
        status: RequestStatus.fetching
      };
    }
    case EcommerceTypes.GET_SHOPPING_CART_FAILURE: {
      return {
        ...state,
        data: INITIAL_STATE.data,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case EcommerceTypes.SHOPPING_CART_BACK_STEP: {
      return {
        ...state,
        data: {
          ...state.data,
          activeStep: state.data.activeStep - 1
        }
      };
    }
    case EcommerceTypes.SHOPPING_CART_NEXT_STEP: {
      return {
        ...state,
        data: {
          ...state.data,
          activeStep: state.data.activeStep + 1
        }
      };
    }
    case EcommerceTypes.SHOPPING_CART_GO_TO_STEP: {
      return {
        ...state,
        data: {
          ...state.data,
          activeStep: action.payload
        }
      };
    }
    case EcommerceTypes.SHOPPING_CART_RESET_CART: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default reducer;
