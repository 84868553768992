import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  // Button,
  AppBar,
  Toolbar,
  Container,
  Stack
} from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// redux
import { useAuthInitializeData } from '../../store/auth/initialize/authInitialize.selectors';
import { useProfileStatus } from '../../store/profile/profile.selectors';
// components
import Logo from '../../components/Logo';
// import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
import AccountPopoverMain from './AccountPopover';
import AccountPopoverDashboard from '../dashboard/AccountPopover';

//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const { isAuthenticated } = useAuthInitializeData();
  const { isSuccess: isSuccessProfile } = useProfileStatus();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            {/* <Logo /> */}
            <img
              src="https://res.cloudinary.com/dwxeri8zv/image/upload/v1631479124/o4y-assets/images/logo-only-for-you_mvxbzo.png"
              alt="logo only for you"
              height={40}
            />
          </RouterLink>
          {/* <Label color="info" sx={{ ml: 1 }}>
            v2.6.0
          </Label> */}
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              {isAuthenticated && isSuccessProfile ? <AccountPopoverDashboard /> : <AccountPopoverMain />}
            </Stack>
          </MHidden>

          {/* <Button variant="contained" target="_blank" href="/pricing">
            Compre agora
          </Button> */}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
