import * as EcommerceTypes from './goToBack.types';
import { RequestStatus } from '../../models/request';

const INITIAL_STATE = {
  data: null,
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EcommerceTypes.SET_GO_TO_BACK: {
      return {
        ...state,
        data: {
          ...state.data,
          urlToGoBack: action.payload
        },
        status: RequestStatus.success
      };
    }
    default:
      return state;
  }
};

export default reducer;
