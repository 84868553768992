// libraries/frameworks
import { applyMiddleware, createStore } from 'redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

// components

// variables
import middlewares from './middlewares';
import reducers from './root.reducers';

const bindMiddleware = (middleware) => {
  const developmentHosts = ['hml.o4y.site.com.s3-website-us-east-1.amazonaws.com', 'localhost'];
  const isDevelopmentStage = developmentHosts.includes(window?.location?.hostname);

  if (isDevelopmentStage) return composeWithDevTools(applyMiddleware(...middleware));
  return applyMiddleware(...middleware);
};

// persist redux in local-storage after close app
const persistConfig = {
  key: 'o4y',
  storage,
  blacklist: [],
  whitelist: ['lessonPlan']
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, bindMiddleware(middlewares));
const persistor = persistStore(store);

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

export { store, persistor, useSelector, useDispatch };
