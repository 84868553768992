// components
import { postRegisterTeacher as postRegisterTeacherService } from './authRegisterTeacherAccount.services';
// variables
import * as AuthTypes from './authRegisterTeacherAccount.types';

// -----------------------------------------------------------------------------------
// REGISTER TEACHER
export const registerTeacherRequest = () => ({
  type: AuthTypes.REGISTER_TEACHER_REQUEST
});

export const registerTeacherSuccess = (payload) => ({
  type: AuthTypes.REGISTER_TEACHER_SUCCESS,
  payload
});

export const registerTeacherFailure = (error) => ({
  type: AuthTypes.REGISTER_TEACHER_FAILURE,
  payload: error
});

export const registerTeacher = (payload) => async (dispatch) => {
  try {
    dispatch(registerTeacherRequest());

    const response = await postRegisterTeacherService(payload);
    dispatch(
      registerTeacherSuccess({
        ...response.data.data,
        email: payload.email,
        password: payload.password
      })
    );

    return { ...response.data.data, email: payload.email, password: payload.password, success: response.data.success };
  } catch (error) {
    dispatch(
      registerTeacherFailure({
        message: 'Occured error in action register teacher account',
        errors: error.response.data.errors
      })
    );
    return { message: 'Occured error in action register teacher account', ...error.response.data };
  }
};
