// components
import { getLessonPlanOptionsGrammar as getLessonPlanOptionsGrammarService } from './lessonPlanOptionsGrammar.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsGrammar.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsGrammarRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_GRAMMAR_REQUEST
});

export const getLessonPlanOptionsGrammarSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_GRAMMAR_SUCCESS,
  payload
});

export const getLessonPlanOptionsGrammarFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_GRAMMAR_FAILURE,
  payload: error
});

export const getLessonPlanOptionsGrammar = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsGrammarRequest());

    const response = await getLessonPlanOptionsGrammarService();
    dispatch(getLessonPlanOptionsGrammarSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsGrammarFailure({
        message: 'Occured error in action getLessonPlanOptionsGrammar',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsGrammar', ...error?.response?.data };
  }
};
