// components
import { getLessonPlanOptionsMethodology as getLessonPlanOptionsMethodologyService } from './lessonPlanOptionsMethodology.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsMethodology.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsMethodologyRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_METHODOLOGY_REQUEST
});

export const getLessonPlanOptionsMethodologySuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_METHODOLOGY_SUCCESS,
  payload
});

export const getLessonPlanOptionsMethodologyFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_METHODOLOGY_FAILURE,
  payload: error
});

export const getLessonPlanOptionsMethodology = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsMethodologyRequest());

    const response = await getLessonPlanOptionsMethodologyService();
    dispatch(getLessonPlanOptionsMethodologySuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsMethodologyFailure({
        message: 'Occured error in action getLessonPlanOptionsMethodology',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsMethodology', ...error?.response?.data };
  }
};
