// components
import { getLessonPlanOptionsDuration as getLessonPlanOptionsDurationService } from './lessonPlanOptionsDuration.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsDuration.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsDurationRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_DURATION_REQUEST
});

export const getLessonPlanOptionsDurationSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_DURATION_SUCCESS,
  payload
});

export const getLessonPlanOptionsDurationFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_DURATION_FAILURE,
  payload: error
});

export const getLessonPlanOptionsDuration = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsDurationRequest());

    const response = await getLessonPlanOptionsDurationService();
    dispatch(getLessonPlanOptionsDurationSuccess([...response?.data.data]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsDurationFailure({
        message: 'Occured error in action getLessonPlanOptionsDuration',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsDuration', ...error?.response?.data };
  }
};
