// components
import { getLessonPlanOptionsEnglishType as getLessonPlanOptionsEnglishTypeService } from './lessonPlanOptionsEnglishType.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsEnglishType.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsEnglishTypeRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ENGLISH_TYPE_REQUEST
});

export const getLessonPlanOptionsEnglishTypeSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ENGLISH_TYPE_SUCCESS,
  payload
});

export const getLessonPlanOptionsEnglishTypeFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ENGLISH_TYPE_FAILURE,
  payload: error
});

export const getLessonPlanOptionsEnglishType = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsEnglishTypeRequest());

    const response = await getLessonPlanOptionsEnglishTypeService();
    dispatch(getLessonPlanOptionsEnglishTypeSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsEnglishTypeFailure({
        message: 'Occured error in action getLessonPlanOptionsEnglishType',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsEnglishType', ...error?.response?.data };
  }
};
