import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.04 111.26">
        <path
          fill="#1a9c49"
          d="M1.44,46.33C-1.21,42.15-.11,38.8,4,36.47,6.29,35.16,7.59,34,6.49,31.08a3.69,3.69,0,0,1,.78-4.14c6.64-6.31,12.46-13.48,20.91-17.8A22.81,22.81,0,0,1,41,6.48c2.5.23,4.8.25,6.89-1.77,6.6-6.33,15.3-6.33,21.68.33,3.14,3.27,5.87,5.05,10.7,3.66s8.43,1.26,10,5.88c1,2.85,2.89,4.55,5.17,6.29,13.18,10,19,23.62,19.53,39.81a11.62,11.62,0,0,1-1,5.8c-1.85,2.34-4.31,2-6.79,1.5-1.78-.33-3.42-1.22-5.25-1.32a10.7,10.7,0,0,0-4.34.66A13.7,13.7,0,0,1,84.5,66.05a5.26,5.26,0,0,0-4.65-.91c-4.32.48-8.19-2.15-12.5-1.75a5,5,0,0,1-2.21-.82,7.51,7.51,0,0,1-1.72-4.5c-1-9.73-6.31-15-16.1-15.9A1.93,1.93,0,0,1,46,40.54c.87-1.7,2.57-1.88,4.31-2.28C46,33.82,41.27,32.17,35.45,35c-1,.48-2.2,1.2-3.2.13s-.37-2.17-.1-3.28,1.2-2.18,1.61-3.48c-2.18,2.82-4.52,5.42-5.78,8.69-1.16,3-3.47,4-6.34,3.26-4.52-1.08-8.48.1-12.28,2.37A8.84,8.84,0,0,0,7.24,44.2a9.62,9.62,0,0,1-2.57,2.13C3.6,46.81,2.52,47.23,1.44,46.33Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6a482e"
          d="M67.1,62.4c4.62-1.47,8.63,2.25,13.16,1.54,1.69,0,2.08,1.36,2.66,2.5.3.58.46,1.23.77,1.81,2.08,4,5.14,7.8,9.61,7.41s7.43-4.39,8.47-9.1a2.57,2.57,0,0,1,.69-1.27c3.79,1,7.52,2.59,11.56,1.18C113.13,79.58,105.51,88.8,96.17,97,95,98.08,93.58,99,92.28,99.93c-1.18,3-4.23,2.7-6.51,3.77-2.07.49-4.24.42-6.28,1.11a11.44,11.44,0,0,1-1.43.2,12.45,12.45,0,0,1-3.55-.29c-1.93-.56-3.85-1-5.78,0-2.32.7-3.61,3.53-6.47,3.08-3.91,1.61-7.79,3.25-12.15,3.44-5.75.25-12.54-4.17-14-9.3a2.87,2.87,0,0,1,1.11-1.73c1.56-1,3-2.35,5.1-1.82,3.23,1.85,6.47,3.67,10.4,2.13,3-.89,5-3.19,7.46-4.91,4.22-3.49,9.16-4.32,14.44-3.9,2.9.55,5.69.78,7.18-2.57,1.7-3.82,1.62-7.66-1.38-10.61-4.7-4.61-6.85-11.38-12.91-14.79C67.15,63.52,67.07,62.91,67.1,62.4Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6a492e"
          d="M60.26,96.79c-2.17,2-4.25,4.06-7.13,5q-3.72-6.43-10.8-4.11c-2.16.15-3.17,2.19-4.93,3L36.15,102c-7.59,2.92-14.49.9-16.92-4.94-1.12-3.6.09-5.43,4-5.25C29.11,95,34.48,93.67,39.57,89.9a18.75,18.75,0,0,1,16.59,1.3C58.22,92.39,60.44,93.81,60.26,96.79Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6a492e"
          d="M14.28,79c-6.93-4.67-9.14-12.39-5.77-20.1.33-.76.93-.78,1.59-.77,2.31-.51,2.72,1.5,3.69,2.76.83,2.8-1.33,6.2,1.77,8.53.9.2.67,2.51,2.36,1.23,1.75-.12,3,1.29,4.58,1.56,6.23,3.73,7.76,6.93,6.28,13.06C27,87.47,24.52,87.35,22,87.08A3.92,3.92,0,0,1,19.12,85c-.45-1.34.25-3-.94-4.13-1-.89-2.45-.66-3.5-1.4C14.54,79.31,14.41,79.13,14.28,79Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6b4a2f"
          d="M19.36,84.13l2,1.87c2.28,1,4.58.57,6.88.09,4.73-.54,8.49,1,11.06,5.11-4.71,3.44-9.5,6.19-15.11,1.69-1.84-1.18-4.06.25-5.91-.86-5.21-2.67-7.15-6.67-5.88-12,.26-1.09,1.11-1,1.88-1.1l.13.11c.88.92,2.18.18,3.15.79A8.31,8.31,0,0,1,19.36,84.13Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6b4a30"
          d="M62.26,107.81c2.05-1.3,3.65-3.28,6.08-4,1.67-.87,3.3-.37,4.93.15s3.18.8,4.76,0h1.31c2.33-.24,4.74-.13,6.88-1.35,1.81-1.36,4.47-.76,6.06-2.63.54,2.58-1.2,4-3,5.18-8.2,5.76-17.3,6.68-26.88,4.73C60.34,109.41,62.64,108.46,62.26,107.81Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6d4d31"
          d="M21.41,73.08c-1.77.26-2.23-2-3.91-2-1.69-.72-2.24-2.28-2.82-3.82-.79-1.92-.49-4-1.13-6l-3.45-3.2c-.86-2.79-1.87-5.56-.81-8.53a.92.92,0,0,1,1-.51,2.7,2.7,0,0,1,.85.93,19.74,19.74,0,0,0,1.88,3.28c.91,1.72,1.8,3.46,3.72,4.31C22.67,61.88,24.4,67.71,21.41,73.08Z"
          transform="translate(0 0)"
        />
        <path
          fill="#429d4a"
          d="M10.23,49l-.94.61c-2.92-.34-5.92-.5-7.85-3.23l3.05-1c1.74.46,3.55-.6,5.27.24a2.66,2.66,0,0,1,1.06.88A1.88,1.88,0,0,1,10.23,49Z"
          transform="translate(0 0)"
        />
        <path
          fill="#a69180"
          d="M18.28,92c2,.12,4.17-1,5.91.86-3-.25-5.59,0-5,4.12C17.83,95.56,18.44,93.72,18.28,92Z"
          transform="translate(0 0)"
        />
        <path
          fill="#c0aa44"
          d="M21.41,73.08c.95-6.1.22-11.55-5.85-14.92C14.05,56.42,14,54.4,14.43,52.3c.34-1.7,2.21-2.12,3-3.46-.68-1.09-2-1-2.86-1.55-1.2-.48-1.54-1.88-2.66-2.46C10.75,44,9.21,43.7,8.65,42.18,12.12,38.67,16.32,37.85,21,39c3.2.83,5.08.16,6.38-3.22a18.33,18.33,0,0,1,8.93-10.18c-.6,3.59-3.91,5.74-3.58,9.25,9.18-4.27,11.86-3.77,20.12,3.86l-6.46,1.67c-.2.34-.67.68-.23,1.08s.82.19,1.22,0c10.47,0,15.07,4.12,17,15.08.34,1.92.59,3.86.89,5.79.88,2.6.39,5.23-.21,7.74-1.42,5.94.57,10.16,5.72,13.3,3.45,2.11,6.59,4.62,4.74,9.47-5.42,0-10.8.16-15.22,4C54.85,90,48,88.13,39.3,91.2c-3.54-1.59-6.43-4.73-10.76-4.38-.88-.47-.73-1.14-.35-1.85C28.53,78.05,27.28,75.87,21.41,73.08Z"
          transform="translate(0 0)"
        />
        <path
          fill="#fefefd"
          d="M75.48,92.84c-.1-4-2.2-6.6-5.53-8.63-6.45-3.93-7.65-6.56-6.18-13.73.55-2.71.11-5.59,1.46-8.15l1.87.07C74.48,66,77.45,73.63,82.56,79.29c2.94,3.25,2.31,7.79-.22,11.55C80.7,93.27,78.38,94.26,75.48,92.84Z"
          transform="translate(0 0)"
        />
        <path
          fill="#fbfbfa"
          d="M102.46,65.29c.41,4.23-4.22,11-8.19,12.06-2.69.69-9-3.34-11-7.22-1.09-2-2-4.13-3-6.19,1.66-1.57,3.22-.35,4.4.51,4.55,3.3,9.1,4.08,14.08,1A3.33,3.33,0,0,1,102.46,65.29Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6f4e2f"
          d="M8.65,42.18c1.37.49,2.84.83,3.73,2.15.82,2.05.18,2.7-1.9,2l-.3.07c-1-1.13-2.87-1.32-3.48-3C6.73,42,7.92,42.47,8.65,42.18Z"
          transform="translate(0 0)"
        />
        <path
          fill="#b1a242"
          d="M6.7,43.41c1.87.16,2.81,1.39,3.48,3-1.9-.3-4.08.94-5.69-1Z"
          transform="translate(0 0)"
        />
        <path
          fill="#79a344"
          d="M47.33,41.46c-1,.8-2.22,1.51-3.13.31-.28-.37,1.23-1.12,2.14-1.35Z"
          transform="translate(0 0)"
        />
        <path
          fill="#bfa944"
          d="M41.39,97c7-2.9,10.77-1.34,11.74,4.82-4.13.9-8,.9-10.85-2.92C41.77,98.37,40.73,98.09,41.39,97Z"
          transform="translate(0 0)"
        />
        <path
          fill="#ccc0b7"
          d="M86.22,102.56c-2,1.69-4.28,2.47-6.88,1.35C81.42,102.38,84,103.2,86.22,102.56Z"
          transform="translate(0 0)"
        />
        <path
          fill="#9d8339"
          d="M41.39,97c.29.64.59,1.27.89,1.9-2-.46-2.85,2.29-4.88,1.81A9.17,9.17,0,0,1,41.39,97Z"
          transform="translate(0 0)"
        />
        <path
          fill="#cabeb4"
          d="M73.28,104.6l-4.94-.79c2-1.73,4-1.34,6-.07A1.63,1.63,0,0,1,73.28,104.6Z"
          transform="translate(0 0)"
        />
        <path
          fill="#e7e2dd"
          d="M73.28,104.6l1.06-.86,3.69.17C76.68,105.76,74.9,104.6,73.28,104.6Z"
          transform="translate(0 0)"
        />
        <path
          fill="#b69f41"
          d="M13.55,61.29A6.74,6.74,0,0,1,15.48,67a3.21,3.21,0,0,1-.14,3.34C10.65,68.11,12.72,64.58,13.55,61.29Z"
          transform="translate(0 0)"
        />
        <path
          fill="#c0aa44"
          d="M19.36,84.13c-1.92-.74-1.85-2.48-2.05-4.08a1.22,1.22,0,0,1,1.93.09C20,81.45,21,82.75,19.36,84.13Z"
          transform="translate(0 0)"
        />
        <path
          fill="#896b35"
          d="M28.19,85c.12.62.23,1.24.35,1.85-2.41-.11-5,1-7.18-.82C23.67,85.84,26.1,86.56,28.19,85Z"
          transform="translate(0 0)"
        />
        <path
          fill="#aa913e"
          d="M15.34,70.31c.05-1.11.09-2.23.14-3.34l2,4.15C16.19,72.42,15.92,71,15.34,70.31Z"
          transform="translate(0 0)"
        />
        <path
          fill="#9e843a"
          d="M19.24,80.14l-1.93-.09c-1.06,0-2.2.11-2.9-1C16,79.42,18,78.19,19.24,80.14Z"
          transform="translate(0 0)"
        />
        <path
          fill="#6f5434"
          d="M10.48,46.29a1.76,1.76,0,0,0,1.9-2c1.78-.36,1.24,1.55,2.1,2.1,1.08,1.43,2.14,2.87,1,4.75l.12,7c-2.32-.86-2.78-2.92-3.21-5-.66-.8-.89-1.9-1.78-2.55-.11-.55-.22-1.11-.34-1.66C10.31,48.07,10.4,47.18,10.48,46.29Z"
          transform="translate(0 0)"
        />
        <path
          fill="#c6cebe"
          d="M10.57,50.62c3.16-.93,1.45,1.52,1.78,2.55C10.67,53.07,10.85,51.69,10.57,50.62Z"
          transform="translate(0 0)"
        />
        <path
          fill="#3b9c48"
          d="M15.44,51.18l-1-4.75c1.92.34,3.85.64,5.45,2.16C18.6,49.88,17.43,51.2,15.44,51.18Z"
          transform="translate(0 0)"
        />
      </svg>
    </Box>
  );
}
