import { shallowEqual, useSelector } from 'react-redux';
import { RequestStatus } from '../models/request';

export const useProfileData = () => useSelector(({ profile }) => profile, shallowEqual);

export const useProfileStatus = () =>
  useSelector(
    ({ profile }) => ({
      isError: profile?.status === RequestStatus.error,
      isFetching: profile?.status === RequestStatus.fetching,
      isIdle: profile?.status === RequestStatus.idle,
      isSuccess: profile?.status === RequestStatus.success
    }),
    shallowEqual
  );
