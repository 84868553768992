// components
import { getLessonPlanOptionsVocabulary as getLessonPlanOptionsVocabularyService } from './lessonPlanOptionsVocabulary.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsVocabulary.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsVocabularyRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_VOCABULARY_REQUEST
});

export const getLessonPlanOptionsVocabularySuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_VOCABULARY_SUCCESS,
  payload
});

export const getLessonPlanOptionsVocabularyFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_VOCABULARY_FAILURE,
  payload: error
});

export const getLessonPlanOptionsVocabulary = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsVocabularyRequest());

    const response = await getLessonPlanOptionsVocabularyService();
    dispatch(getLessonPlanOptionsVocabularySuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsVocabularyFailure({
        message: 'Occured error in action getLessonPlanOptionsVocabulary',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsVocabulary', ...error?.response?.data };
  }
};
