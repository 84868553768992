// components
import { getPurchases as getPurchasesService } from './orderPurchases.services';
// variables
import * as OrderTypes from './orderPurchases.types';

// -----------------------------------------------------------------------------------
// GET PURCHASES
export const getPurchasesRequest = () => ({
  type: OrderTypes.GET_PURCHASES_REQUEST
});

export const getPurchasesSuccess = (payload) => ({
  type: OrderTypes.GET_PURCHASES_SUCCESS,
  payload
});

export const getPurchasesFailure = (error) => ({
  type: OrderTypes.GET_PURCHASES_FAILURE,
  payload: error
});

export const getPurchases = () => async (dispatch) => {
  try {
    dispatch(getPurchasesRequest());

    const response = await getPurchasesService();
    dispatch(getPurchasesSuccess(response));

    return response;
  } catch (error) {
    dispatch(
      getPurchasesFailure({
        message: 'Occured error in action getPurchases',
        errors: error.response.data.errors
      })
    );
    return { message: 'Occured error in action getPurchases', ...error.response.data };
  }
};
