// components
import { getLessonPlanOptionsMultimidia as getLessonPlanOptionsMultimidiaService } from './lessonPlanOptionsMultimidia.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsMultimidia.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsMultimidiaRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_MULTIMIDIA_REQUEST
});

export const getLessonPlanOptionsMultimidiaSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_MULTIMIDIA_SUCCESS,
  payload
});

export const getLessonPlanOptionsMultimidiaFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_MULTIMIDIA_FAILURE,
  payload: error
});

export const getLessonPlanOptionsMultimidia = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsMultimidiaRequest());

    const response = await getLessonPlanOptionsMultimidiaService();
    dispatch(getLessonPlanOptionsMultimidiaSuccess([...response?.data.data]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsMultimidiaFailure({
        message: 'Occured error in action getLessonPlanOptionsMultimidia',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsMultimidia', ...error?.response?.data };
  }
};
