// components
import { getLessonPlanOptionsWord as getLessonPlanOptionsWordService } from './lessonPlanOptionsWord.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsWord.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsWordRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_WORD_REQUEST
});

export const getLessonPlanOptionsWordSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_WORD_SUCCESS,
  payload
});

export const getLessonPlanOptionsWordFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_WORD_FAILURE,
  payload: error
});

export const getLessonPlanOptionsWord = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsWordRequest());

    const response = await getLessonPlanOptionsWordService();
    dispatch(getLessonPlanOptionsWordSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsWordFailure({
        message: 'Occured error in action getLessonPlanOptionsWord',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsWord', ...error?.response?.data };
  }
};
