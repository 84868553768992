// libraries/frameworks
import axios from 'axios';

// components
import { request, responseError, responseSuccess } from './api-methods';

// variables
const envBaseURL = process.env.REACT_APP_REACT_API_REGISTER_API_URL;
const secondsInMinute = 60;
const millisecondsInSecond = 1000;
const timeout = secondsInMinute * millisecondsInSecond;

export const globalHeadersConfig = {
  'Content-Type': 'application/json;charset=UTF-8'
};

const axiosInstance = axios.create({
  baseURL: envBaseURL,
  headers: globalHeadersConfig,
  timeout
});

axiosInstance.interceptors.request.use(request);
axiosInstance.interceptors.response.use(responseSuccess, responseError);

const get = (props) =>
  axiosInstance.request({
    ...props,
    method: 'GET'
  });

const post = (props) =>
  axiosInstance.request({
    ...props,
    method: 'POST'
  });

const put = (props) =>
  axiosInstance.request({
    ...props,
    method: 'PUT'
  });

const patch = (props) =>
  axiosInstance.request({
    ...props,
    method: 'PATCH'
  });

const del = (props) =>
  axiosInstance.request({
    ...props,
    method: 'DELETE'
  });

export default {
  del,
  get,
  patch,
  post,
  put
};
