import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// import useUser from '~/hooks/user.hook';
// import { USERS_PROFILES } from '~/constants/profiles';
// import { findWalletsByCompanyId, findWalletsCompanyIn } from '~/services/api';
import { getLessonPlanOptionsLevel } from '../store/lesson-plan/options/level/lessonPlanOptionsLevel.actions';
import { getLessonPlanOptionsSkill } from '../store/lesson-plan/options/skill/lessonPlanOptionsSkill.actions';
import { getLessonPlanOptionsMultimidia } from '../store/lesson-plan/options/multimidia/lessonPlanOptionsMultimidia.actions';
import { getLessonPlanOptionsDuration } from '../store/lesson-plan/options/duration/lessonPlanOptionsDuration.actions';
import { getLessonPlanOptionsFileFormatType } from '../store/lesson-plan/options/file-format-type/lessonPlanOptionsFileFormatType.actions';
import { getLessonPlanOptionsFileType } from '../store/lesson-plan/options/file-type/lessonPlanOptionsFileType.actions';
import { getLessonPlanOptionsVocabulary } from '../store/lesson-plan/options/vocabulary/lessonPlanOptionsVocabulary.actions';
import { getLessonPlanOptionsSubject } from '../store/lesson-plan/options/subject/lessonPlanOptionsSubject.actions';
import { getLessonPlanOptionsActivity } from '../store/lesson-plan/options/activity/lessonPlanOptionsActivity.actions';
import { getLessonPlanOptionsGrammar } from '../store/lesson-plan/options/grammar/lessonPlanOptionsGrammar.actions';
import { getLessonPlanOptionsPronunciation } from '../store/lesson-plan/options/pronunciation/lessonPlanOptionsPronunciation.actions';
import { getLessonPlanOptionsFunctional } from '../store/lesson-plan/options/functional/lessonPlanOptionsFunctional.actions';
import { getLessonPlanOptionsProfession } from '../store/lesson-plan/options/profession/lessonPlanOptionsProfession.actions';
import { getLessonPlanOptionsWord } from '../store/lesson-plan/options/word/lessonPlanOptionsWord.actions';
import { getLessonPlanOptionsEnglishType } from '../store/lesson-plan/options/englishtype/lessonPlanOptionsEnglishType.actions';
import { getLessonPlanOptionsMethodology } from '../store/lesson-plan/options/methodology/lessonPlanOptionsMethodology.actions';

const LessonOptionsContext = createContext({});

LessonOptionsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function LessonOptionsProvider({ children }) {
  const dispatch = useDispatch();

  //   const { profile, id: userId, email } = useUser();
  //   const [opened, setOpened] = useState(true);
  //   const [wallets, setWallets] = useState([]);
  //   const [walletsSelected, setWalletsSelected] = useState([]);
  //   const [orderType, setOrderType] = useState('');
  //   const [ordersTypeTab, setOrdersTypeTab] = useState(profile === USERS_PROFILES.PRODUCER ? 'received' : 'sended');
  //   const [saveCSV, setSaveCSV] = useState({ column: {}, data: {}, name: '' });

  //   const loadWallets = useCallback(async () => {
  //     try {
  //       const { data } =
  //         profile === USERS_PROFILES.COOPERATIVE
  //           ? await findWalletsByCompanyId(userId, encodeURIComponent(email))
  //           : await findWalletsCompanyIn(userId);

  //       setWallets(data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, [userId, profile, email]);

  //   useEffect(() => {
  //     loadWallets();
  //   }, [loadWallets]);

  useEffect(() => {
    dispatch(getLessonPlanOptionsLevel());
    dispatch(getLessonPlanOptionsSkill());
    dispatch(getLessonPlanOptionsMultimidia());
    dispatch(getLessonPlanOptionsDuration());
    dispatch(getLessonPlanOptionsFileFormatType());
    dispatch(getLessonPlanOptionsFileType());
    dispatch(getLessonPlanOptionsVocabulary());
    dispatch(getLessonPlanOptionsSubject());
    dispatch(getLessonPlanOptionsActivity());
    dispatch(getLessonPlanOptionsGrammar());
    dispatch(getLessonPlanOptionsPronunciation());
    dispatch(getLessonPlanOptionsFunctional());
    dispatch(getLessonPlanOptionsProfession());
    dispatch(getLessonPlanOptionsWord());
    dispatch(getLessonPlanOptionsEnglishType());
    dispatch(getLessonPlanOptionsMethodology());
  }, [dispatch]);

  return (
    <LessonOptionsContext.Provider
      value={
        {
          // opened,
          // setOpened,
          // wallets,
          // setWallets,
          // walletsSelected,
          // setWalletsSelected,
          // orderType,
          // setOrderType,
          // ordersTypeTab,
          // setOrdersTypeTab,
          // saveCSV,
          // setSaveCSV,
          // loadWallets
        }
      }
    >
      {children}
    </LessonOptionsContext.Provider>
  );
}

export function useLessonOptions() {
  const context = useContext(LessonOptionsContext);

  if (!context) throw new Error('O useLessonOptions só pode ser utilizado dentro do LessonOptionsProvider');

  return {
    ...context
  };
}

export default LessonOptionsProvider;
