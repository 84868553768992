import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// auth
import authInitialize from './auth/initialize/authInitialize.reducers';
import authRegisterTeacherAccount from './auth/register/teacher/account/authRegisterTeacherAccount.reducers';
import authRegisterTeacherVerify from './auth/register/teacher/verify/authRegisterTeacherVerify.reducers';
import authLogin from './auth/login/authLogin.reducers';
import authChangePassword from './auth/change-password/authChangePassword.reducers';
// dashboard
import studentManagerAddOrEdit from './dashboard/student-manager/add-or-edit/studentManagerAddOrEdit.reducers';
import studentManagerList from './dashboard/student-manager/list/studentManager.reducers';
// profile
import profile from './profile/profile.reducers';
import myLessonsList from './my-lessons/list/myLessons.reducers';
import lessonPlanList from './lesson-plan/list/lessonPlanList.reducers';
import lessonPlanSelected from './lesson-plan/selected/lessonPlanSelected.reducers';
import lessonPlanFilter from './lesson-plan/filter/lessonPlanFilterProduct.reducers';
import lessonPlanCategory from './lesson-plan/category/lessonPlanCategory.reducers';
import lessonPlanPopularSubjects from './lesson-plan/popular-subjects/lessonPlanPopularSubjects.reducers';
import lessonPlanPopularDownload from './lesson-plan/download/lessonPlanDownloadProduct.reducers';

import lessonPlanOptionsLevel from './lesson-plan/options/level/lessonPlanOptionsLevel.reducers';
import lessonPlanOptionsSkill from './lesson-plan/options/skill/lessonPlanOptionsSkill.reducers';
import lessonPlanOptionsMultimidia from './lesson-plan/options/multimidia/lessonPlanOptionsMultimidia.reducers';
import lessonPlanOptionsDuration from './lesson-plan/options/duration/lessonPlanOptionsDuration.reducers';
import lessonPlanOptionsFileFormatType from './lesson-plan/options/file-format-type/lessonPlanOptionsFileFormatType.reducers';
import lessonPlanOptionsFileType from './lesson-plan/options/file-type/lessonPlanOptionsFileType.reducers';
import lessonPlanOptionsVocabulary from './lesson-plan/options/vocabulary/lessonPlanOptionsVocabulary.reducers';
import lessonPlanOptionsSubject from './lesson-plan/options/subject/lessonPlanOptionsSubject.reducers';
import lessonPlanOptionsActivity from './lesson-plan/options/activity/lessonPlanOptionsActivity.reducers';
import lessonPlanOptionsGrammar from './lesson-plan/options/grammar/lessonPlanOptionsGrammar.reducers';
import lessonPlanOptionsPronunciation from './lesson-plan/options/pronunciation/lessonPlanOptionsPronunciation.reducers';
import lessonPlanOptionsFunctional from './lesson-plan/options/functional/lessonPlanOptionsFunctional.reducers';
import lessonPlanOptionsProfession from './lesson-plan/options/profession/lessonPlanOptionsProfession.reducers';

import lessonPlanOptionsMethodology from './lesson-plan/options/methodology/lessonPlanOptionsMethodology.reducers';
import lessonPlanOptionsWord from './lesson-plan/options/word/lessonPlanOptionsWord.reducers';
import lessonPlanOptionsEnglishType from './lesson-plan/options/englishtype/lessonPlanOptionsEnglishType.reducers';

// ecommerce
import ecommerceCheckoutCartAddProduct from './ecommerce/checkout/cart/add-product/ecommerceCheckoutCartAddProduct.reducers';
import ecommerceCheckout from './ecommerce/checkout/cart/list/ecommerceCheckout.reducers';
import ecommerceCheckoutCartRemoveProduct from './ecommerce/checkout/cart/remove-product/ecommerceCheckoutCartRemoveProduct.reducers';
import ecommerceCheckoutCartCreateShoppingCart from './ecommerce/checkout/cart/create-shopping-cart/ecommerceCheckoutCartCreateShoppingCart.reducers';
// order
import purchase from './order/purchases/orderPurchases.reducers';
import finishPurchase from './order/finish-purchase/orderFinishPurchase.reducers';
// product
import wishlistAdd from './wishlist/add/wishlistAdd.reducers';
import wishlistList from './wishlist/list/wishlistList.reducers';
import wishlistRemove from './wishlist/remove/wishlistRemove.reducers';
// history
import historyGoToBack from './history/goToBack/goToBack.reducers';

// import blogReducer from '../redux/slices/blog';
// import productReducer from '../redux/slices/product';

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout']
// };

// const ecommercePersistConfig = {
//   key: 'ecommerce',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout']
// };

const reducer = combineReducers({
  auth: combineReducers({
    initialize: persistReducer({ key: 'o4y-auth-initialize', storage }, authInitialize),
    register: combineReducers({
      teacher: combineReducers({
        account: persistReducer({ key: 'o4y-register-teacher', storage }, authRegisterTeacherAccount),
        verify: persistReducer({ key: 'o4y-register-teacher-verify', storage }, authRegisterTeacherVerify)
      })
    }),
    login: persistReducer({ key: 'o4y-login', storage }, authLogin),
    changePassword: persistReducer({ key: 'o4y-change-password', storage }, authChangePassword)
  }),
  dashboard: combineReducers({
    studentManager: combineReducers({
      addOrEdit: studentManagerAddOrEdit,
      list: studentManagerList
    })
  }),
  profile: persistReducer({ key: 'o4y-profile', storage }, profile),
  // blog: blogReducer,
  lessonPlan: combineReducers({
    list: lessonPlanList,
    selected: lessonPlanSelected,
    filter: lessonPlanFilter,
    category: lessonPlanCategory,
    download: lessonPlanPopularDownload,
    popularSubjects: lessonPlanPopularSubjects,
    options: combineReducers({
      level: lessonPlanOptionsLevel,
      skill: lessonPlanOptionsSkill,
      multimidia: lessonPlanOptionsMultimidia,
      duration: lessonPlanOptionsDuration,
      fileFormatType: lessonPlanOptionsFileFormatType,
      fileType: lessonPlanOptionsFileType,
      vocabulary: lessonPlanOptionsVocabulary,
      subject: lessonPlanOptionsSubject,
      activity: lessonPlanOptionsActivity,
      grammar: lessonPlanOptionsGrammar,
      pronunciation: lessonPlanOptionsPronunciation,
      functional: lessonPlanOptionsFunctional,
      profession: lessonPlanOptionsProfession,
      methodology: lessonPlanOptionsMethodology,
      word: lessonPlanOptionsWord,
      englishType: lessonPlanOptionsEnglishType
    })
  }),
  // initial: () => 'Cesar Filho',
  // product: persistReducer(productPersistConfig, productReducer),
  // ecommerce: persistReducer(ecommercePersistConfig, ecommerce),
  ecommerce: combineReducers({
    checkout: combineReducers({
      add: ecommerceCheckoutCartAddProduct,
      list: persistReducer({ key: 'o4y-checkout', storage }, ecommerceCheckout),
      remove: ecommerceCheckoutCartRemoveProduct,
      createShoppingCart: ecommerceCheckoutCartCreateShoppingCart
    })
  }),
  order: combineReducers({
    purchase: persistReducer({ key: 'o4y-purchase', storage }, purchase),
    finishPurchase
  }),
  history: combineReducers({
    goToBack: persistReducer({ key: 'o4y-go-to-back', storage }, historyGoToBack)
  }),
  wishlist: combineReducers({
    add: wishlistAdd,
    list: wishlistList,
    remove: wishlistRemove
  }),
  myLesson: combineReducers({
    list: myLessonsList
  })
});

export default reducer;
