import * as LessonPlanSelectedTypes from './lessonPlanSelected.types';
import { RequestStatus } from '../../models/request';

const INITIAL_STATE = {
  data: {
    id: null,
    name: null,
    thumbnailImageUrl: null
  },
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LessonPlanSelectedTypes.LESSEON_PLAN_SELECTED_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case LessonPlanSelectedTypes.LESSEON_PLAN_SELECTED_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: RequestStatus.success
      };
    }
    case LessonPlanSelectedTypes.LESSEON_PLAN_SELECTED_FAILURE: {
      return {
        ...state,
        data: null,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case LessonPlanSelectedTypes.LESSEON_PLAN_SELECTED_RESET: {
      return {
        ...state,
        data: null,
        error: null,
        status: RequestStatus.idle
      };
    }
    default:
      return state;
  }
};

export default reducer;
