import { Outlet } from 'react-router-dom';
// material

// redux
import { useProfileStatus } from '../../store/profile/profile.selectors';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function CheckoutLayout() {
  const { isSuccess: isSuccessProfile } = useProfileStatus();

  return (
    <Page title="Ecommerce: Checkout | Only For You" loading={!isSuccessProfile}>
      <Outlet />
    </Page>
  );
}
