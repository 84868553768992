// components
import { getLessonPlanOptionsProfession as getLessonPlanOptionsProfessionService } from './lessonPlanOptionsProfession.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsProfession.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsProfessionRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PROFESSION_REQUEST
});

export const getLessonPlanOptionsProfessionSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PROFESSION_SUCCESS,
  payload
});

export const getLessonPlanOptionsProfessionFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PROFESSION_FAILURE,
  payload: error
});

export const getLessonPlanOptionsProfession = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsProfessionRequest());

    const response = await getLessonPlanOptionsProfessionService();
    dispatch(getLessonPlanOptionsProfessionSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsProfessionFailure({
        message: 'Occured error in action getLessonPlanOptionsProfession',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsProfession', ...error?.response?.data };
  }
};
