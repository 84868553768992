// libraries/frameworks
import axios from 'axios';

export const getPurchases = async () => {
  const accessToken = window.localStorage.getItem('accessToken');

  const { data: purchasedProducts } = await axios.post(
    process.env.REACT_APP_REACT_API_GET_PURCHASES,
    {
      pageIndex: 1
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  const filterPurchasedProducts = purchasedProducts.data
    .map((purchase) => purchase.orderedProducts.map((itensPurchase) => itensPurchase.productId)) // map all productId in all purchases
    .reduce((arrays, itensArrays) => arrays.concat(itensArrays), []) // combine all arrays with productId in one array
    .filter((item, pos, self) => self.indexOf(item) === pos); // filter uniques productId

  return { purchasedProducts, filterPurchasedProducts };
};
