// libraries/frameworks
import reduxThunk from 'redux-thunk';
import promise from 'redux-promise';

// components

// variables
const Middlewares = [reduxThunk, promise];

export default Middlewares;
