import { shallowEqual, useSelector } from 'react-redux';
import { RequestStatus } from '../../models/request';

export const useAuthInitializeData = () => useSelector(({ auth }) => auth?.initialize, shallowEqual);

export const useAuthInitializeStatus = () =>
  useSelector(
    ({ auth }) => ({
      isError: auth?.initialize?.status === RequestStatus.error,
      isFetching: auth?.initialize?.status === RequestStatus.fetching,
      isIdle: auth?.initialize?.status === RequestStatus.idle,
      isSuccess: auth?.initialize?.status === RequestStatus.success
    }),
    shallowEqual
  );
