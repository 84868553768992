const openURLs = [
  '/shoppingcart',
  '/order',
  '/product/getproductwishlist',
  '/product/includeproducttowishlist',
  '/product/removeproductfromwishlist',
  '/lesson/getmylessons',
  '/product/getlessonproductfilestodownload',
  '/teacher',
  '/user/getuserprofile',
  '/user/login'
];

const request = async (params) => {
  // console.log('Starting Request', params);
  const accessToken = window.localStorage.getItem('accessToken');

  const { data, headers: headersRequest, url } = params;
  const secondsInMinute = 60;
  const millisecondsInSecond = 1000;
  const timeout = secondsInMinute * millisecondsInSecond;
  const headers = headersRequest;

  if (url && openURLs.some((url) => url.startsWith(url))) {
    try {
      headers.Authorization = `Bearer ${accessToken}`;
      headers.cesar = 'Cesar Filho';
    } catch {
      // Do nothing.
    }
  }

  return {
    ...params,
    timeout,
    data: JSON.stringify(data),
    headers
  };
};

const errorMessages = {
  0: 'Erro genérico, exception não tratada.',
  400: 'Algo deu errado. Tente novamente mais tarde.',
  403: 'Erro inesperado.',
  404: 'Recurso não encontrado.',
  500: 'Erro de comunicação interna. Tente novamente mais tarde.'
};

// eslint-disable-next-line arrow-body-style
const responseSuccess = (response) => {
  // console.log('End Response', response);
  return response;
};

const responseError = (error) => {
  console.log('responseError', error);
  const HTTPStatusCode = error?.response?.status || 0;

  switch (HTTPStatusCode) {
    case 400:
    case 404:
    case 500:
      console.error('HTTP status code: ', error);
      break;
    default:
      throw new Error(JSON.stringify(`HTTP status code: ${HTTPStatusCode} - ${errorMessages[0]}`));
  }

  return Promise.reject(error.response);
};

export { request, responseSuccess, responseError };
