import * as StudentManagerAddTypes from '../add/studentManagerAdd.types';
import * as StudentManagerEditTypes from '../edit/studentManagerEdit.types';
import { RequestStatus } from '../../../models/request';
// import reducerAdd from '../add/studentManagerAdd.reducers';
// import reducerEdit from '../edit/studentManagerEdit.reducers';

const INITIAL_STATE = {
  data: [],
  edit: false,
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  // ...reducerEdit(state, action),
  // ...reducerAdd(state, action, INITIAL_STATE)
  switch (action.type) {
    case StudentManagerAddTypes.ADD_STUDENT_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case StudentManagerAddTypes.ADD_STUDENT_SUCCESS: {
      return {
        ...state,
        data: [action.payload],
        edit: true,
        error: null,
        status: RequestStatus.success
      };
    }
    case StudentManagerAddTypes.ADD_STUDENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case StudentManagerAddTypes.ADD_STUDENT_CLEAR: {
      return INITIAL_STATE;
    }

    case StudentManagerEditTypes.EDIT_STUDENT_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case StudentManagerEditTypes.EDIT_STUDENT_SUCCESS: {
      return {
        ...state,
        error: null,
        status: RequestStatus.success
      };
    }
    case StudentManagerEditTypes.EDIT_STUDENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case StudentManagerEditTypes.SET_EDIT_STUDENT: {
      return {
        ...state,
        data: [action.payload],
        edit: true,
        error: null
      };
    }
    default:
      return state;
  }
};

export default reducer;
