import * as StudentManagerTypes from './studentManager.types';
import { RequestStatus } from '../../../models/request';

const INITIAL_STATE = {
  lastPage: 0,
  totalOfItens: 0,
  data: [],
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StudentManagerTypes.GET_LIST_OF_STUDENTS_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case StudentManagerTypes.GET_LIST_OF_STUDENTS_SUCCESS: {
      const listOfStudents = action.payload.students.map((item) => ({ ...item, isVerified: false, status: 'active' }));

      return {
        ...state,
        data: [...state.data, ...listOfStudents],
        totalOfItens: action.payload.totalOfItens,
        lastPage: action.payload.page > state.lastPage ? action.payload.page : state.lastPage,
        error: null,
        status: RequestStatus.success
      };
    }
    case StudentManagerTypes.GET_LIST_OF_STUDENTS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case StudentManagerTypes.CLEAR_LIST_OF_STUDENTS: {
      return INITIAL_STATE;
    }

    case StudentManagerTypes.DELETE_STUDENT_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case StudentManagerTypes.DELETE_STUDENT_SUCCESS: {
      return {
        ...state,
        error: null,
        status: RequestStatus.success
      };
    }
    case StudentManagerTypes.DELETE_STUDENT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }

    default:
      return state;
  }
};

export default reducer;
