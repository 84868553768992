import API from '../../../api/api-request';

export const postLogin = async (payload) => {
  const result = await API.post({
    url: process.env.REACT_APP_REACT_API_LOGIN,
    data: payload
  });

  return result;
};
