import { isEmpty } from 'lodash';

export const normalizeBackendToFrontend = () => ({
  getShoppingCart: (payload) => {
    if (isEmpty(payload)) return [];

    const { shoppingcartitens } = payload;

    const normalized = shoppingcartitens.map((shoppingcartitem) => {
      const {
        id: shoppingCartItemId,
        product: {
          id,
          name,
          price: { value },
          slug,
          thumbnailImageUrl
        },
        quantity
      } = shoppingcartitem;

      return {
        id,
        name,
        cover: thumbnailImageUrl,
        available: 1,
        price: value,
        color: '',
        size: '',
        quantity,
        subtotal: quantity * value,
        slug,
        shoppingCartItemId
      };
    });

    return normalized;
  }
});
