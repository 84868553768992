import * as AuthTypes from './authInitialize.types';
import * as AuthLoginTypes from '../login/authLogin.types';
import { RequestStatus } from '../../models/request';

const INITIAL_STATE = {
  data: null,
  isAuthenticated: false,
  isInitialized: false,
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthLoginTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        error: null,
        status: RequestStatus.success
      };
    }
    case AuthTypes.INITIALIZE:
      return {
        ...state,
        ...action.payload
      };
    case AuthLoginTypes.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
