// libraries/frameworks
import axios from 'axios';

export const getUserProfile = async () => {
  const accessToken = window.localStorage.getItem('accessToken');

  const result = await axios.get(process.env.REACT_APP_REACT_API_GET_USER_PROFILE, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (result?.data?.data?.shoppingCart?.id) {
    localStorage.setItem('shoppingCartId', result?.data?.data?.shoppingCart?.id);
  }

  return result;
};
