// components
import { getLessonPlanOptionsFunctional as getLessonPlanOptionsFunctionalService } from './lessonPlanOptionsFunctional.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsFunctional.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsFunctionalRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FUNCTIONAL_REQUEST
});

export const getLessonPlanOptionsFunctionalSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FUNCTIONAL_SUCCESS,
  payload
});

export const getLessonPlanOptionsFunctionalFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FUNCTIONAL_FAILURE,
  payload: error
});

export const getLessonPlanOptionsFunctional = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsFunctionalRequest());

    const response = await getLessonPlanOptionsFunctionalService();
    dispatch(getLessonPlanOptionsFunctionalSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsFunctionalFailure({
        message: 'Occured error in action getLessonPlanOptionsFunctional',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsFunctional', ...error?.response?.data };
  }
};
