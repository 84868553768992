export const LESSEON_PLAN_FILTER_PRODUCT_CATEGORY = 'LESSEON_PLAN_FILTER_PRODUCT_CATEGORY';
export const LESSEON_PLAN_FILTER_PRODUCT_DURATION = 'LESSEON_PLAN_FILTER_PRODUCT_DURATION';
export const LESSEON_PLAN_FILTER_PRODUCT_FILE_FORMAT_TYPE = 'LESSEON_PLAN_FILTER_PRODUCT_FILE_FORMAT_TYPE';
export const LESSEON_PLAN_FILTER_PRODUCT_FILE_TYPE = 'LESSEON_PLAN_FILTER_PRODUCT_FILE_TYPE';
export const LESSEON_PLAN_FILTER_PRODUCT_LEVEL = 'LESSEON_PLAN_FILTER_PRODUCT_LEVEL';
export const LESSEON_PLAN_FILTER_PRODUCT_SKILL = 'LESSEON_PLAN_FILTER_PRODUCT_SKILL';
export const LESSEON_PLAN_FILTER_PRODUCT_MULTIMIDIA = 'LESSEON_PLAN_FILTER_PRODUCT_MULTIMIDIA';
export const LESSEON_PLAN_FILTER_PRODUCT_TEXT = 'LESSEON_PLAN_FILTER_PRODUCT_TEXT';
export const LESSEON_PLAN_FILTER_PRODUCT_VOCABULARY = 'LESSEON_PLAN_FILTER_PRODUCT_VOCABULARY';
export const LESSEON_PLAN_FILTER_PRODUCT_SUBJECT = 'LESSEON_PLAN_FILTER_PRODUCT_SUBJECT';
export const LESSEON_PLAN_FILTER_PRODUCT_ACTIVITY = 'LESSEON_PLAN_FILTER_PRODUCT_ACTIVITY';
export const LESSEON_PLAN_FILTER_PRODUCT_GRAMMAR = 'LESSEON_PLAN_FILTER_PRODUCT_GRAMMAR';
export const LESSEON_PLAN_FILTER_PRODUCT_PRONUNCIATION = 'LESSEON_PLAN_FILTER_PRODUCT_PRONUNCIATION';
export const LESSEON_PLAN_FILTER_PRODUCT_FUNCTIONAL = 'LESSEON_PLAN_FILTER_PRODUCT_FUNCTIONAL';
export const LESSEON_PLAN_FILTER_PRODUCT_PROFESSION = 'LESSEON_PLAN_FILTER_PRODUCT_PROFESSION';
export const LESSEON_PLAN_FILTER_PRODUCT_WORD = 'LESSEON_PLAN_FILTER_PRODUCT_WORD';
export const LESSEON_PLAN_FILTER_PRODUCT_ENGLISH_TYPE = 'LESSEON_PLAN_FILTER_PRODUCT_ENGLISH_TYPE';
export const LESSEON_PLAN_FILTER_PRODUCT_METHODOLOGY = 'LESSEON_PLAN_FILTER_PRODUCT_METHODOLOGY';

export const LESSEON_PLAN_FILTER_RESET = 'LESSEON_PLAN_FILTER_RESET';
