// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  add: getIcon('ic_add'),
  users: getIcon('ic_users'),
  logout: getIcon('ic_logout'),
  wishlist: getIcon('ic_wishlist'),
  buylist: getIcon('ic_buylist'),
  booklist: getIcon('ic_booklist')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      }
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ]
  },
  {
    subheader: 'Materiais Didático',
    items: [
      {
        title: 'Lista de Desejo',
        path: PATH_DASHBOARD.general.wishlist,
        icon: ICONS.wishlist
      },
      {
        title: 'Meus Materiais',
        path: PATH_DASHBOARD.general.myLessons,
        icon: ICONS.booklist
      }
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Gerenciar',
  //   items: [
  //     // MANAGEMENT : USER
  //     // {
  //     //   title: 'user',
  //     //   path: PATH_DASHBOARD.user.root,
  //     //   icon: ICONS.user,
  //     //   children: [
  //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //     //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //     //     { title: 'account', path: PATH_DASHBOARD.user.account }
  //     //   ]
  //     // },
  //     {
  //       title: 'Meus Alunos',
  //       path: PATH_DASHBOARD.user.list,
  //       icon: ICONS.user
  //     },
  //     {
  //       title: 'Minhas Compras',
  //       path: PATH_DASHBOARD.eCommerce.list,
  //       icon: ICONS.user
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //       ]
  //     }
  //   ]
  // },

  //     // // MANAGEMENT : BLOG
  //     // {
  //     //   title: 'blog',
  //     //   path: PATH_DASHBOARD.blog.root,
  //     //   icon: ICONS.blog,
  //     //   children: [
  //     //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //     //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //     //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //     //   ]
  //     // }
  //   ]
  // },
  {
    subheader: 'Alunos',
    items: [
      {
        title: 'Meus Alunos',
        path: PATH_DASHBOARD.student.management,
        icon: ICONS.users
      },
      {
        title: 'Adicionar Aluno',
        path: PATH_DASHBOARD.student.addStudent,
        icon: ICONS.add
      }
    ]
  },
  {
    subheader: 'Conta',
    items: [
      {
        title: 'Meu Perfil',
        path: PATH_DASHBOARD.profile.account,
        icon: ICONS.user
      }
      // {
      //   title: 'Minhas Compras',
      //   path: PATH_DASHBOARD.general.myLessons,
      //   icon: ICONS.buylist
      // }
      // {
      //   title: 'Sair',
      //   path: '',
      //   icon: ICONS.logout
      // }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
];

export default sidebarConfig;
