import * as LessonPlanOptionsTypes from './lessonPlanOptionsFileType.types';
import { RequestStatus } from '../../../models/request';

const INITIAL_STATE = {
  data: null,
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_TYPE_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: RequestStatus.success
      };
    }
    case LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_FILE_TYPE_FAILURE: {
      return {
        ...state,
        data: null,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    default:
      return state;
  }
};

export default reducer;
