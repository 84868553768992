// components
import { getLessonPlanOptionsPronunciation as getLessonPlanOptionsPronunciationService } from './lessonPlanOptionsPronunciation.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsPronunciation.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsPronunciationRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PRONUNCIATION_REQUEST
});

export const getLessonPlanOptionsPronunciationSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PRONUNCIATION_SUCCESS,
  payload
});

export const getLessonPlanOptionsPronunciationFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_PRONUNCIATION_FAILURE,
  payload: error
});

export const getLessonPlanOptionsPronunciation = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsPronunciationRequest());

    const response = await getLessonPlanOptionsPronunciationService();
    dispatch(getLessonPlanOptionsPronunciationSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsPronunciationFailure({
        message: 'Occured error in action getLessonPlanOptionsPronunciation',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsPronunciation', ...error?.response?.data };
  }
};
