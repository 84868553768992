// variables
import * as AuthTypes from './authInitialize.types';
// utils
import { isValidToken, setSession } from '../../../utils/jwt';

// -----------------------------------------------------------------------------------
// INITIALIZE
export const initializeRequest = () => ({
  type: AuthTypes.INITIALIZE_REQUEST
});

export const initialize = () => async (dispatch) => {
  try {
    dispatch(initializeRequest());
    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);

      return dispatch({
        type: AuthTypes.INITIALIZE,
        payload: { isAuthenticated: true, isInitialized: true }
      });
    }

    return dispatch({
      type: AuthTypes.INITIALIZE,
      payload: { data: null, isAuthenticated: false, isInitialized: true }
    });
  } catch (error) {
    dispatch({
      type: AuthTypes.INITIALIZE,
      payload: { data: null, isAuthenticated: false, isInitialized: true, error: error.response.data }
    });

    return { message: 'Occured error in action initialize', ...error.response.data };
  }
};
