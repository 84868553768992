// redux
import { useProfileData } from '../store/profile/profile.selectors';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const {
    data: { name, picture }
  } = useProfileData();

  return (
    <MAvatar src={picture} alt={name} color={picture ? 'default' : createAvatar(name).color} {...other}>
      {createAvatar(name).name}
    </MAvatar>
  );
}
