import { isEmpty } from 'lodash';
// components
import { postLogin as postLoginService } from './authLogin.services';
// variables
import * as AuthTypes from './authLogin.types';
// utils
import { isValidToken, setSession } from '../../../utils/jwt';
// redux
import { getUserProfile } from '../../profile/profile.actions';
import { getShoppingCart } from '../../ecommerce/checkout/cart/list/ecommerceCheckout.actions';
import { getPurchases } from '../../order/purchases/orderPurchases.actions';

// -----------------------------------------------------------------------------------
// LOGIN
const loginRequest = () => ({
  type: AuthTypes.LOGIN_REQUEST
});

const loginSuccess = (payload) => ({
  type: AuthTypes.LOGIN_SUCCESS,
  payload
});

const loginFailure = (error) => ({
  type: AuthTypes.LOGIN_FAILURE,
  payload: error
});

export const login = (payload) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const {
      data: { data: auth }
    } = await postLoginService(payload);
    const { token } = auth;

    if (isValidToken(token)) {
      setSession(token);
    }

    await dispatch(loginSuccess(auth));

    const userProfileData = await dispatch(getUserProfile());

    if (!isEmpty(userProfileData.shoppingCart)) {
      const {
        shoppingCart: { id: shoppingCartId }
      } = userProfileData;

      await dispatch(getShoppingCart(shoppingCartId));
    }

    await dispatch(getPurchases());
  } catch (error) {
    dispatch(
      loginFailure({
        message: 'Occured error in action login',
        errors: error.response.data.errors
      })
    );
    return { message: 'Occured error in action login', ...error.response.data };
  }
};
