import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  // {
  //   title: 'Components',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.components,
  // },
  {
    title: 'O4Y',
    path: '/o4y',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'O4Y',
        items: [
          { title: 'Sobre nós', path: PATH_PAGE.about },
          { title: 'Contato', path: PATH_PAGE.contact },
          { title: 'Termos de Uso', path: PATH_PAGE.termsOfUse },
          { title: 'Política de Privacidade', path: PATH_PAGE.privacyPolicy }
        ]
      }
    ]
  },
  {
    title: 'Material didático',
    path: PATH_PAGE.lessonPlan,
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Material didático',
        items: [
          { title: 'Pesquisar usando filtros', path: PATH_PAGE.lessonPlan },
          { title: 'Meus materiais', path: PATH_DASHBOARD.general.myLessons }
          // { title: 'Pesquisar por categoria', path: '/lesson-plan/categories' }
          // { title: 'Meus materiais', path: PATH_DASHBOARD.general.myLessons }
        ]
      }
    ]
  },
  {
    title: 'Metodologia',
    path: '/metodologia',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Metodologia',
        items: [
          { title: 'Teste de Nível - CEFR', path: PATH_PAGE.methodologyLevelTestCEFR },
          { title: 'Sala de aula invertida', path: PATH_PAGE.methodologyFlippedClassroom }
        ]
      }
    ]
  },
  {
    title: 'Gestão de alunos',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.student.management
  },

  // {
  //   title: 'Blog',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_DASHBOARD.blog.root
  // },
  {
    title: 'Dashboard',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.root
  }
  // {
  //   title: 'Contato',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.contact
  // }
  // {
  //   title: 'Documentation',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_DOCS,
  // },
];

export default menuConfig;
