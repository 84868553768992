// components
import { getLessonPlanOptionsActivity as getLessonPlanOptionsActivityService } from './lessonPlanOptionsActivity.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsActivity.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsActivityRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ACTIVITY_REQUEST
});

export const getLessonPlanOptionsActivitySuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ACTIVITY_SUCCESS,
  payload
});

export const getLessonPlanOptionsActivityFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_ACTIVITY_FAILURE,
  payload: error
});

export const getLessonPlanOptionsActivity = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsActivityRequest());

    const response = await getLessonPlanOptionsActivityService();
    dispatch(getLessonPlanOptionsActivitySuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsActivityFailure({
        message: 'Occured error in action getLessonPlanOptionsActivity',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsActivity', ...error?.response?.data };
  }
};
