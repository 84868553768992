import * as CreateShoppingCartTypes from './ecommerceCheckoutCartCreateShoppingCart.types';
import { RequestStatus } from '../../../../models/request';

const INITIAL_STATE = {
  data: [],
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateShoppingCartTypes.REGISTER_SHOPPING_CART_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case CreateShoppingCartTypes.REGISTER_SHOPPING_CART_SUCCESS: {
      return {
        ...state,
        data: [action.payload],
        error: null,
        status: RequestStatus.success
      };
    }
    case CreateShoppingCartTypes.REGISTER_SHOPPING_CART_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case CreateShoppingCartTypes.REGISTER_SHOPPING_CART_CLEAR: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default reducer;
