// libraries/frameworks
import axios from 'axios';

export const getShoppingCart = async (shoppingCartId) => {
  const accessToken = window.localStorage.getItem('accessToken');

  const result = await axios.get(process.env.REACT_APP_REACT_API_GET_SHOPPING_CART, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      shoppingCartId
    }
  });

  return result;
};
