// components
import { getLessonPlanOptionsSubject as getLessonPlanOptionsSubjectService } from './lessonPlanOptionsSubject.services';
// variables
import * as LessonPlanOptionsTypes from './lessonPlanOptionsSubject.types';

// -----------------------------------------------------------------------------------
// GET POPULAR SUBJECTS
export const getLessonPlanOptionsSubjectRequest = () => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SUBJECT_REQUEST
});

export const getLessonPlanOptionsSubjectSuccess = (payload) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SUBJECT_SUCCESS,
  payload
});

export const getLessonPlanOptionsSubjectFailure = (error) => ({
  type: LessonPlanOptionsTypes.LESSEON_PLAN_OPTIONS_SUBJECT_FAILURE,
  payload: error
});

export const getLessonPlanOptionsSubject = () => async (dispatch) => {
  try {
    dispatch(getLessonPlanOptionsSubjectRequest());

    const response = await getLessonPlanOptionsSubjectService();
    dispatch(getLessonPlanOptionsSubjectSuccess([...response?.data.data.itens]));

    return response?.data;
  } catch (error) {
    dispatch(
      getLessonPlanOptionsSubjectFailure({
        message: 'Occured error in action getLessonPlanOptionsSubject',
        errors: error?.response?.data.errors
      })
    );
    return { message: 'Occured error in action getLessonPlanOptionsSubject', ...error?.response?.data };
  }
};
