import * as AuthTypes from './authRegisterTeacherVerify.types';
import * as AuthLoginTypes from '../../../login/authLogin.types';
import { RequestStatus } from '../../../../models/request';

const INITIAL_STATE = {
  data: null,
  error: null,
  status: RequestStatus.idle
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.REGISTER_TEACHER_VERIFY_REQUEST:
      return {
        ...state,
        status: RequestStatus.fetching
      };
    case AuthTypes.REGISTER_TEACHER_VERIFY_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: RequestStatus.success
      };
    }
    case AuthTypes.REGISTER_TEACHER_VERIFY_FAILURE: {
      return {
        ...state,
        error: action.payload,
        status: RequestStatus.error
      };
    }
    case AuthLoginTypes.LOGIN_SUCCESS: {
      return {
        state: INITIAL_STATE
      };
    }
    default:
      return state;
  }
};

export default reducer;
