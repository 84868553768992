// components
import { getShoppingCart as getShoppingCartService } from './ecommerceCheckout.services';
import { normalizeBackendToFrontend } from './ecommerceCheckout.normalize';
// variables
import * as EcommerceTypes from './ecommerceCheckout.types';

// -----------------------------------------------------------------------------------
// GET SHOPPING CART
const getShoppingCartRequest = () => ({
  type: EcommerceTypes.GET_SHOPPING_CART_REQUEST
});

const getShoppingCartSuccess = (payload) => ({
  type: EcommerceTypes.GET_SHOPPING_CART_SUCCESS,
  payload
});

const getShoppingCartFailure = (error) => ({
  type: EcommerceTypes.GET_SHOPPING_CART_FAILURE,
  payload: error
});

export const getShoppingCart = (shoppingCartId) => async (dispatch) => {
  try {
    const { getShoppingCart: normalizeGetShoppingCart } = normalizeBackendToFrontend();
    dispatch(getShoppingCartRequest());

    const {
      data: { data: response }
    } = await getShoppingCartService(shoppingCartId);

    const normalizedShoppingCart = normalizeGetShoppingCart(response);

    dispatch(getShoppingCartSuccess(normalizedShoppingCart));

    return response;
  } catch (error) {
    dispatch(
      getShoppingCartFailure({
        message: 'Occured error in action getShoppingCart',
        errors: error
      })
    );
    return { message: 'Occured error in action getShoppingCart', ...error?.response?.data };
  }
};

// -----------------------------------------------------------------------------------
// RESET CART

export const resetCart = () => async (dispatch) => {
  localStorage.removeItem('shoppingCartId');

  dispatch({
    type: EcommerceTypes.SHOPPING_CART_RESET_CART
  });
};

// -----------------------------------------------------------------------------------
// SHOPPING CART STEPS
export const onBackStep = () => ({
  type: EcommerceTypes.SHOPPING_CART_BACK_STEP
});

export const onNextStep = () => ({
  type: EcommerceTypes.SHOPPING_CART_NEXT_STEP
});

export const onGotoStep = (payload) => ({
  type: EcommerceTypes.SHOPPING_CART_GO_TO_STEP,
  payload
});
